.proTipContainer {
    border: 1px dashed var(--primary);
    width: 80%;
    margin-bottom: 20px;
}

.proTipHeaderContainer {
    display: flex;
    justify-content: center;
    margin-top: -15px;
}

.proTipHeader {
    background-color: var(--primary);
    color: var(--text);
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 12px;
    display: inline;
    padding: 9px 19px;
    border-radius: 6px;
    margin-bottom: 10px;
}

.proTipContentContainer{    
    max-height: 10vh;
    overflow-y: scroll;
}
.proTipContent,
.proTipSubContent {
    font-family: "Inter", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 0 5px 5px 5px;
    line-height: 18px;
}


@media (max-width: 510px) {
    .proTipHeader {
        margin-bottom: 5px;
    }
}