.checklistContainer{
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start; 
  flex-grow: 1;
  width: calc(100% - 20px);
  max-width: 1000px;
  padding: 10px 20px;
  overflow-x: auto;
  flex-grow: 1;
  }

  .callToAction{
    display: flex;
    margin-top: 20px;
  }

  .nameInput {
    max-width: 800px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .callToActionFooter{
    margin: 20px 0;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .groupNotes{
    margin: 0 0 20px 0;
    font-style: italic;
  }

  .checklistTop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .checklistHeading{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin:10px 0;
    color: var(--primaryDark);
    font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
    font-weight: bold;
    padding:0;
    font-size:30px;
    text-align: center;
  }

  
.checklistSections{
  margin-top: 20px;
  overflow: auto;
  align-items: stretch;
  justify-content: flex-start; 
  width: 100%;
 
  }

  @media (max-width: 768px) {
    .checklistTop{
      justify-content: center;
   }
   .checklistButtonGroup{margin-bottom: 10px;}
   }
   
  