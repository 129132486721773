.checklistSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  border-radius: 12px;
  overflow: hidden;
}

.checklistItemList {
  overflow: auto;
  flex:1
}

/*.checklistItemsContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  position: relative;
}*/
.checklistItemsContainer {
  width: 100%;
  overflow: auto;
  margin: auto;
  flex: 1;
}

.checklistItemsContainerOpen {
  background-color: var(--white);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.checklistHeaderItemsLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--primary);
  overflow: hidden;
  cursor: pointer;
  min-height:72px;
}

.checklistChildTitle {
  padding: 20px 20px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.checklistHeaderItemsLeft h2{
  color: var(--text);
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin: 0;
  display: flex;
  font-size: 22px;
  flex: 1;
}