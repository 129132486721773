.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  max-width: 800px;
  padding: 10px 16px;
  margin-top: -15px;
}

.settingsHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 93%;
}

.settingsHeadingTextHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.settingsHeadingText {
  color: var(--primaryDark);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0 50px 0 0;
  padding: 0;
  font-size: 30px;
}

.settingsHeadingUnderline {
  background-color: var(--primaryDark);
  height: 3px;
  width: 60px;
  border-radius: 20%;
}

.settingsContent {
  background-color: var(--white);
  border-radius: 20px;
  padding: 20px 18px;
  margin: 25px 20px 20px 20px;
  width: 92%;
  /*https://html-css-js.com/css/generator/box-shadow/*/
  -webkit-box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
}


.settings_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0px 9px 10px;
  flex: 1
}

.settings_buttonIconHolder {
  width: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.settings_buttonIcon {
  height: 26px;
}

.settings_buttonToggleIcon {
  height: 26px;
  margin: 5px 0;
}

.settings_avatarIcon {
  border: 2px solid var(--black);
  border-radius: 50%;
}

.settings_buttonText {
  color: var(--text);
  margin: 0 6px 0 22px;
  flex: 1;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 15px;
  text-align: left;
}

.settingsCurrent {
  background: var(--lightgrey400);
  border-radius: 5px;
}

.settings_buttonArrow {
  width: 10px;
  padding-right: 10px;
}

.switchHolder {

  display: flex;

}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--primaryDark)
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--primaryDark)
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.settings_editGroupHolder {
  margin-left: 50px;
  margin-bottom: 30px;
}

.settingsHeader {
  background-color: var(--primary);
  color: var(--text);
  border-radius: 0 16px 16px 0;
  padding: 10px 13px;
  margin: 15px 0 20px -20px;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: 500;
  /*https://html-css-js.com/css/generator/box-shadow/*/
  -webkit-box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
}