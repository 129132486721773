.checklistItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #FFF;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 6px;
  border-radius: 12px;
}


.checklistItemContainer:not(:last-child):before {
  content: '';
  position: absolute;
  width: calc(100% - 30px);
  height: 1px;
  left: 15px;
  bottom: 0;
  background: var(--lightgrey300);
}

.checklistItemNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  column-gap: 10px;
}

.checklistItemNameContainerSpread {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

@media (max-width: 400px) {
  .checklistItemNameContainer {
    /*display: block;
    flex: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;*/
  }
}

.checklistItemName {
  margin-left: 5px;
  border: none;
  padding: 0px 5px;
  border-radius: 0px;
  text-align: left;
  color: var(--text);
}

.checklistItemName::placeholder {
  color: #00000031;
}

.checklistItemName,
.checklistItemName::placeholder {
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
}


.checklistItemProgressIcon {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-left: 5px;
}

.numberIconDiv {
  min-width: 30px;
}


.numberText {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  min-width: 24px;
  text-align: center;
}

.numberContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.organiseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  max-width: 140px;
  margin-left: 2px;
}

.shareItemContainer{
  display: flex;
  padding:5px 0;
  flex-direction: row;
  column-gap: 5px;
  justify-content: space-between;
  width:100%;
  border-bottom: 1px solid var(--grey)
}
.shareItemContainerCell{
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-weight: 600;

}
.shareItemContainerCellInfo{
  display: flex;
  flex-direction: column;

}
.shareItemContainerCellName{
  display: flex;
}
.itemStatus{
  font-style: italic;
  font-weight: normal;
}