.image-dropdown {
  position: relative;
  width: 30%;
}

.dropdown {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.selected-value {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 15px;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  margin: 0px 5px;
  padding: 10px 8px;
  overflow: hidden;
  height: 44px;
  box-sizing: border-box;
}

.selected-value img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border: none;
}

.arrow {
  font-size: 12px;
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><path d='M 8 1 L 5 4.17 L 2 1 L 1 2 L 5 6 L 9 2 Z' stroke='black' stroke-width='1' /></svg>");
}

.arrow.up {
  /* transform: rotate(180deg); */
}

.options {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 150px;
  /* Adjust the max height as needed */
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: none;
  width: 100%;
}

.open .options {
  display: block;
  z-index: 1;
}

.options li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.options li:hover {
  background-color: #f0f0f0;
}

.options img {
  border: none;
}

@media (max-width: 510px) {
  .image-dropdown {
    width: 35%;
  }

  .selected-value,
  .options {
    font-size: 14px;
  }

  .selected-value img,
  .options img {
    width: 20px;
    height: 20px;
  }
}