.Group_avatars {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100vw;
  overflow-x: auto;
}

.Group_membersCircle {
  background-color: #fff;
  border: 2px solid var(--lightgrey300);
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: var(--text);
  font-family: "Retroica", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Group_membersselectedImg {
  border-color: var(--primary);
  position: relative;
  z-index: 2;
}


.Group_addCircle {
  color: var(--text);
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  padding: 3px;
  margin-left: 4px;
  border: none;
}

.Group_addCircle img {
  width: 23px;
}

.imgSmall {
  width: 30px;
  height: 30px;
}

.imgMedium {
  width: 30px;
  height: 30px;
}

.imgLarge {
  width: 40px;
  height: 40px;
}

.imgHuge {
  width: 60px;
  height: 60px;
}

.Group_left_part_avatars {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 10px;
}

.Group_left_part_avatars img {
  margin-left: -10px;
}

.Group_left_part_avatars button {
  margin-bottom: 5px;
}