.icon_share {
    width: 16px;
}

.shareContainer{
    width:100%
}
.shareItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    background-color: #FFF;
    box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
    margin: 1px 0 1px 0;
    padding: 10px;
}

.shareItemNameContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 30px);
    margin-bottom: 10px;

}

.shareItemName {
    font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 16px;

}

.shareItemNotes {
    font-style: italic;
    margin-left: 10px;

}

.shareSubItemHolder {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 30px);
    background-color: var(--white);
    border: none;
    text-align: left;
    color: var(--text);
    font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
    font-size: 16px;
    gap: 5px;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 2px solid var(--primary);
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.shareButtonHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
  }
  .shareButtonHolder button{
    max-width: 60%;
  }

  .shareSocialButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    column-gap: 10px;
  }
