.default {
  background-color: var(--primary);
  padding: 0 20px;
  font-size: 16px;
  color: var(--text);
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  min-height: auto;
  border-radius: 15px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  text-wrap: nowrap;
}

.primary {
  background-color: var(--primary);
  padding: 16px 20px;
  font-size: 18px;
  color: var(--text);
  font-weight: bold;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  border: none;
  cursor: pointer;
  min-height: auto;
  border-radius: 16px;
  width: 100%;
}

.secondary {
  background-color: var(--secondary);
  padding: 16px 20px;
  font-size: 18px;
  color: var(--text);
  font-weight: bold;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  border: none;
  cursor: pointer;
  min-height: auto;
  border-radius: 16px;
  width: 100%;
}

.save {
  background-color: var(--primary);
  padding: 20px;
  font-size: 16px;
  color: var(--text);
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  min-height: auto;
  border-radius: 20px;
  font-weight: bold;
  margin-left: 10px;
  width: 50%;
}

.cancel {
  background-color: var(--grey);
  padding: 20px;
  font-size: 16px;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  min-height: auto;
  border-radius: 20px;
  font-weight: bold;
  margin-right: 10px;
  width: 50%;
}

.huge {
  background-color: var(--primary);
  /* border-radius: 30px; */
  padding: 20px;
  font-size: 14px;
  color: var(--text);
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  min-height: auto;
  margin: 10px;
}

.borderless {
  background: none;
  padding: 10px;
  font-size: 16px;
  font-family: "Retroica", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  color: var(--text);
  border: none;
  box-shadow: none;
  cursor: pointer;
  min-height: auto;
  text-decoration: underline;
  font-weight: bold;
}

.noStyle {
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  cursor: pointer;
  min-height: auto;
  font-size: inherit;
  font-family: inherit;
}

.flex {
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  cursor: pointer;
  min-height: auto;
  font-size: inherit;
  font-family: inherit;
  display: flex;
  width: 100%;
}

.addButtonIcon {
  background-color: var(--lightgrey100);
  padding: 0 10px;
  font-size: 24px;
  color: var(--black200);
  font-weight: 400;
  line-height: normal;
  border: none;
  cursor: pointer;
  min-height: auto;
  width: 40px;
  height: 40px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  white-space: nowrap;
  display: inline-block;
}

.addButtonOrganiseIcon {
  background-color: var(--primary);
  padding: 0 10px;
  margin-left: -20px;
  font-size: 16px;
  color: var(--text);
  font-weight: bold;
  border: none;
  /*https://html-css-js.com/css/generator/box-shadow/*/
  -webkit-box-shadow: -8px 1px 9px -5px rgba(0, 0, 0, 0.43);
  box-shadow: -8px 1px 9px -5px rgba(0, 0, 0, 0.43);
  cursor: pointer;
  min-height: auto;
  border-radius: 15px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  white-space: nowrap;
}

.moreButtonIcon {
  background-color: var(--primary);
  padding: 0 10px;
  font-size: 16px;
  margin-left: -5px;
  color: var(--text);
  font-weight: bold;
  border: none;
  -webkit-box-shadow: 8px 1px -9px -5px rgba(0, 0, 0, 0.43);
  box-shadow: 8px 1px -9px -5px rgba(0, 0, 0, 0.43);
  cursor: pointer;
  min-height: auto;
  border-radius: 0 15px 15px 0;
  font-weight: bold;
  width: 100%;
  height: 40px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  white-space: nowrap;
}

.addButtonLabel {
  background-color: var(--primary);
  padding: 0 35px 0 20px;
  font-size: 16px;
  color: var(--text);
  font-weight: bold;
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  min-height: auto;
  border-radius: 15px;
  width: 100%;
  height: 40px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  text-wrap: nowrap;
  line-height: 16px;
  text-align: left;
}

.light {
  background: none;
  padding: 14px 5px;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  text-wrap: nowrap;
  color: var(--black300);
  font-size: 14px;
  font-weight: 600;
  background-color: var(--lightgrey100);
  border: none;
  max-width: 120px;
  width: 100%;
}

.reject {
  background-color: var(--red);
  padding: 16px 20px;
  font-size: 18px;
  color: var(--white);
  font-weight: bold;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  border: none;
  cursor: pointer;
  min-height: auto;
  border-radius: 16px;
  width: 100%;
}

.methodButton {
  border: none;
  background: transparent;
  display: flex;
  align-items: flex-start;
  margin-left: 2px;
  padding: 0;
}

.methodButton img {
  width: 26px;
  border-radius: 50%;
  box-sizing: border-box;
}

.addEventButton {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 6px 15px;
  border-radius: 12px;
  border: 1px solid var(--lightgrey300);
}

.addEventButton svg {
  margin-left: 8px;
}

.cancelEventButton {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 6px 7px;
  border-radius: 12px;
  border: 1px solid var(--lightgrey300);
  font-weight: bold;
  font-size: 15px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.cancelEventButton span:nth-child(2) {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.cancelEventButton span:nth-child(2) svg {
  transform: rotate(45deg);
  color: var(--red100);
}
.hidden{display:none}

.round {
  background-color: var(--secondary);
  border: none;
  cursor: pointer;
  min-height: auto;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  color: var(--white);
}