.progressImageIcon{
    width:20px;
    height:20px;
    vertical-align: middle;
    flex:1;
}

.itemStatusContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .itemStatus{
    margin: 0 10px;
    font-size: 14px;
    white-space: nowrap;
  }