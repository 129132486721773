.itemOrganiseContainer{
    display: flex;
    width: 100%;
}

.organiseContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 22px;
    box-sizing: border-box;
    height: calc(100% - 96px);
}

.organiseIconsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    flex: 1;
}

.organiseIconsContainer button {
    margin-left: auto;
}

.organiseMethodIconTitle {
    font-size: 7px;
}

.organiseMethodIcon {
    display: flex;
    align-items: center;
    margin-left: 2px;
}

.organiseAvatarIcons {
    display: flex;
    margin-left: 4px;
    border-radius: 50%;
    border: solid 1px var(--green);
}

.organiseAvatarIcons img {
    border: none;
}

.proTipContainer {
    border: 1px dashed var(--primary);
    width: 80%;
    margin-bottom: 20px;
}

.proTipHeaderContainer {
    display: flex;
    justify-content: center;
    margin-top: -15px;
}

.proTipHeader {
    background-color: var(--primary);
    color: var(--text);
    font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
    font-weight: bold;
    display: inline;
    padding: 5px 10px;
    border-radius: 10px;

}

.proTipContent {
    font-family: "Retroica", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    padding: 10px;

}

.organiseSubItemsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    overflow-y: auto;
}

.organiseSubItemsAddContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 40px);
    padding: 10px 0;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey);
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.organiseSubItemsAddName {
    display: flex;
    flex-direction: column;
}
.itemStatus{
    margin: 0;
    font-weight: normal;
    font-size: 14px;
  }
.organiseFooter {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    padding: 10px 0;
}


.avatarBadge {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    margin-left: 25px;
    margin-top: -35px;
    font-size: 7px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: var(--black400);
    background-color: var(--white);
    border: 1px solid var(--green);
}

.checklistItemName {
    background-color: var(--white);
    border: none;
    padding: 5px;
    border-radius: 0px;
    text-align: left;
    color: var(--text);
    display: flex;
    flex:3;
    align-items: center;
}

.checklistItemName::placeholder {
    color: #00000031;
}

.checklistItemName,
.checklistItemName::placeholder {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.checklistItemHireIcon{
    margin:0 5px 2px 5px;
    padding-bottom: 2px;
}

.editItemNotes {
    padding: 20px;
    background: none;
    background-color: var(--white);
    border: none;
    font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    width: calc(90% - 40px);
    font-size: 14px;
    margin-bottom: 20px;
    /* flex-grow: 1; */
    /* height: 100px; */
    box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
    border-radius: 20px;
}

.organiseContainer .light {
    max-width: 100%;
}

.numberIconDiv {
    min-width: 30px;
  }
  
  .needText {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    min-width: 24px;
    text-align: center;
    padding-right: 10px;
  }

  .numberText {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    min-width: 24px;
    text-align: center;
  }
  
  .numberContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

.callToActionContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  
  
  .hireButtonHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0 10px 0;
    width: 100%;
  }
  .hireButtonHolder button{
    max-width: 60%;
  }