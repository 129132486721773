.imgHolder {
  cursor: pointer;
  margin: 10px;
  width: 138px;
  height: 114px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.imgHolder:last-child {
  order: -1;
}

.img {
  width: 138px;
  height: 114px;
}