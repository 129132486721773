.checklistContainer {
  /*display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start; 
  flex-grow: 1;*/
  width: 80vw;
  max-width: 1000px;
  padding: 32px 20px;
  overflow: auto;
}
.checklistTopTitle{
  padding: 0 10px;
}

.checklistTitle {
  color: var(--text);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 22px;
}

.checklistsubTitle {
  margin:10px 0;
}

.checklistTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

.checklistBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

.checklistHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.checklistHeadingTextHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.checklistHeadingText {
  color: var(--primaryDark);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0 50px 0 0;
  padding: 0;
  font-size: 30px;
}

.checklistHeadingUnderline {
  background-color: var(--primaryDark);
  height: 3px;
  width: 60px;
  border-radius: 20%;
}

.checklistSaveBar {
  width: 90%;
  margin: 20px 0 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checklistButtonGroup {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  width: 100%;
  justify-content: flex-end;
}

.checklistButtonHolder {
  display: flex;
}
.checklistButtonHolder button{
  width: auto;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.checklistSections {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  /*.checklistTop {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }*/


  .checklistSections {
    margin-left: -5px;
    margin-right: -5px;
  }

  .checklistContainer {
    padding: 10px 5px;
    width: 100vw;
    max-width: 100vw;
  }
}