.notificationsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  max-width: 800px;
  padding: 10px 15px;
  margin-top: -15px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.notificationsHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.notificationsHeadingTextHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notificationsHeadingText {
  color: var(--primaryDark);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0 50px 0 0;
  padding: 0;
  font-size: 30px;
}

.notificationsHeadingUnderline {
  background-color: var(--primaryDark);
  height: 3px;
  width: 60px;
  border-radius: 20%;
  margin-bottom: 20px;
}

.notificationContent {
  background-color: var(--white);
  border-radius: 20px;
  padding: 20px 15px;
  margin: 30px 0 5px 0;
  width: 100%;
  /*https://html-css-js.com/css/generator/box-shadow/*/
  -webkit-box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}

.notificationAvatar {
  
}

.notificationTextContent {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  padding: 0 10px;
}

.notificationDate {
  color: var(--grey);
  font-size: 10px;
}

.notificationMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.notificationText {
  margin-top: 10px;
  color: var(--black);
  flex: 1;
}

.notificationNotes {
  color: var(--grey);
  margin-top: 10px;
}

.notificationButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 500px) {
  


}