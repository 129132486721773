.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.containerTop{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.containerMiddleTop{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 0;
  max-height: 50%;
  flex-grow: 1;
  max-width: 600px;
}

.containerMiddleBottom{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 420px;
  margin: 10px;
  max-width: 95vw;
  min-height: 100px;
}

.activeOrganiserButtons{
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 10px;
}

@media (max-height: 700px) {
  .containerMiddleBottom {
    min-height: 0;
    }

}

.containerMiddleBottom button {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-weight: normal;
}


.containerBottom{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 10px 20px;
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

.homeWorld {
  height: 50%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeBannerText {
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 18px;
  text-align: center;
  padding: 0 20px 10px 20px;
}

.countdownText {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 16px;
  text-align: center;
  padding: 10px;
}

.hbt1 {
  transform: translate(20px, 85px) rotate(-30deg);
}

.hbt2 {
  transform: translate(20px, 85px) rotate(-30deg);
}





.artistQuoteContainer {
  padding: 20px;
  font-size: 18px;
  color: var(--text);
  text-align: center;
}

.artistQuote {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.artistName {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.warningContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--red);
  padding: 20px;
  font-size: 16px;
  color: var(--text);
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  min-height: auto;
  border-radius: 20px;
  text-align: center;
}

.warning {
  font-weight: bold;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  color: var(--white);

}



.acknowledgement {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}
