:root {
  --primary: #2CFFA8;
  --primaryDark: #000000;
  --text: #000000;
  --secondary: #F8A49E;
  --gradient1: #F8FBF4;
  --gradient2: #E3EDD0;
  --green: #17CC7E;
  --lightgreen: #EDFCF1;
  --lightgreen100: rgba(23, 204, 126, 0.10);
  --lightgreen200: #B4F7D8;
  --white: #ffffff;
  --black: #222423;
  --black100: #333333;
  --black200: #1F1D10;
  --black300: #333;
  --black400: #000;
  --black500: #424242;
  --grey: #7c7c7c;
  --grey100: #999;
  --lightgrey: #d7d7d7;
  --lightgrey100: #f6f6f6;
  --lightgrey200: #CECECE;
  --lightgrey300: #DCDCDC;
  --lightgrey400: #E6E6E6;
  --red: #b10000;
  --red100: #F56B70;

  --green-light-gradient: #cafcd6;
}

@font-face {
  font-family: 'MadeTommy';
  src: local('MadeTommy'),
    url('./fonts/MadeTommyExtraBold.ttf') format('truetype'),
    url('./fonts/MadeTommyExtraBold.otf') format('otf');
}

@font-face {
  font-family: 'Retroica';
  src: local('Retroica'), url('./fonts/Retroica.ttf') format('truetype')
}


html {
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  color: #131C1A;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  height: 100%;
}

.content {
  width: 100%;
  display: flex;
  overflow-x: auto;
  flex: 1;
  justify-content: center;
}

.content-notifications {
  width: 100%;
  overflow-x: auto;
  flex: 1 1;
}

.bg {
  background: var(--lightgreen);
  height: 100%;
}

.bg-green-gradient {
  height: 100%;
  background: rgb(202, 252, 214);
  background: linear-gradient(0deg, var(--green-light-gradient) 0%, var(--white) 40%, var(--white) 60%, var(--green-light-gradient) 100%);
}

.bg-green-gradient-100 {
  background: rgb(202, 252, 214);
  background: linear-gradient(58.41deg, #B8F4CA -7.31%, #DBF9E4 15.98%, #F5FDF7 37.47%, #FFFFFF 50.26%, #B8F4CA 113.38%);
}

.flex {
  display: flex;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

input,
select {
  border-radius: 17px;
  border: solid 1px var(--primary);
  padding: 19px 20px;
  width: 100%;
  box-sizing: border-box;
  color: var(--black300);
}

select {
  color: var(--black300);
  background-color: var(--white);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="checkbox"] {
  width: 16px;
  margin-right: 10px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.headerImage img {
  max-width: 100px;
  margin-bottom: -20px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.pledgeCheckboxHolder {
  margin: 0 50px;
  text-align: center;
}

.pledgeCheckbox {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 6px 15px;
  border-radius: 12px;
  border:  1px solid var(--lightgrey300);
}

.passwordInputDiv {
  position: relative;
}

.passwordInputDiv input {
  padding-right: 46px;
}

.passwordVisibilityIcon {
  position: absolute;
  right: 20px;
  top: 14px;
  cursor: pointer;
}

#root a {
  color: #131C1A;
  text-decoration: underline;
}

.loginForm {
  overflow-x: auto;
  max-width: 90vw;
}

.loginButton {
  max-width: 420px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagline {
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
}

.underlinedInlineLink {
  display: inline;
  cursor: pointer;
  text-decoration: underline;
}

.note {
  font-style: italic;
  padding: 20px;
}

.errorMessage {
  padding: 5px;
  color: var(--red);
  font-weight: bold;
}

.launchLogoHolder {
  display: flex;
  justify-content: center;
}

img.launchLogo {
  width: 100px;
  height: 62px;
  margin-bottom: 10px;
}

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 44px;
  height: 44px;
  margin: 0 3px;
  border-radius: 10px;
  position: relative;
}

.moreCategoryButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--lightgrey100);
  height: 40px;
  width: 40px;
  position: relative;
  box-sizing: border-box;
}

.moreCategoryButton:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 0px;
  width: 1px;
  height: calc(100% - 4px);
  background: var(--lightgrey200);
}

.logoImages {
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.logoImages img {
  margin: 30px;
  height: 100px;
  width: auto;
  max-width: calc(100vw - 60px);
}


@media (max-width: 400px) {
  .launchLogoHolder {
    display: none;
  }

  .pledgeCheckboxHolder {
    font-size: 12px;
  }

  #root input[type="checkbox"] {
    padding: 5px;
    margin: 0 5px;
    vertical-align: middle;
  }
}

.bg-primary {
  background-color: var(--primary);
}

.react-select-container {
  width: 300px;
  margin-bottom: 12px;
}

.react-select-container .react-select__control {
  min-height: 54px;
  border-color: var(--lightgrey300);
  border-radius: 17px;
}

.react-select-container .react-select__control:hover {
  border-color: var(--lightgrey300);
}

.react-select-container .react-select__placeholder,
.react-select-container .react-select__input-container,
.react-select-container .react-select__single-value,
.react-select-container .react-select__option,
.react-select-container .react-select__menu-list {
  font-size: 12px;
  color: var(--black300);
  font-family: "Inter", "Segoe UI", Helvetica, Arial, sans-serif;
}

.react-select-container .react-select__option--is-selected {
  background-color: var(--primary);
}

.react-select-container .react-select__option--is-focused {
  background-color: var(--lightgreen);
}

.react-select-container .react-select__indicator-separator,
.react-select-container .react-select__dropdown-indicator {
  display: none;
}

.pb-7 {
  padding-bottom: 70px;
  box-sizing: border-box;
}