
.worldImg {
  height: 100%;
  max-height: 300px;
  max-width: 300px;
  /*width: 400px;
  height: auto;*/
  }
  @media (max-width: 400px) {
    .worldImg {
     width:100px;
     height:100px;
     max-width: 100vw;
      }
  
  }